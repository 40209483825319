import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/FOUuVe2ViR8">
    <SEO title="Saved and on Mission - Imperatives" />
  </Layout>
)

export default SermonPost
